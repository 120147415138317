(function($) {

    var $mainNav = $(".site-nav"),
        $magicLine = $('.site-nav .magic-line'),
        $activeItemWidth = 0,
        $activeItemPosition = 0;

    $mainNav.append("<div class='magic-line magic-line--top'></div>");

    $mainNav.each(function() {
        var $magicLine = $(this).find('.magic-line');

        // Position the magic line on page load
        positionMagicLine($magicLine, $activeItemPosition, $activeItemPosition, $activeItemWidth);

        // On hover, animate it
        $(".site-nav .menu > li:not(.has-button)").hover(function() {
            $el = $(this);
            leftPos = $el.position().left;
            newWidth = $el.width();
            $magicLine.stop().animate({
                left: leftPos,
                width: newWidth
            });

        }, function() {
            $magicLine.stop().animate({
                left: $magicLine.data("origLeft"),
                width: $magicLine.data("origWidth")
            });
        });
    });


    /**
     *
     * @param $magicLine
     * @param $left
     * @param $origLeft
     * @param $width
     */
    function positionMagicLine( $magicLine, $left, $origLeft, $width ) {
        $magicLine.width( $width )
            .css("left", $left )
            .data("origLeft", $origLeft)
            .data("origWidth", $width );
    }


    $(window).on('load', function() {
        $(".site-nav").each(function() {
            var $activeItem = $(this).find(".active");

            if ( $activeItem.is('*') ) {
                $activeItemWidth = $activeItem.width();
                $activeItemPosition = $activeItem.position().left;
            }
        } );

        positionMagicLine($magicLine, $activeItemPosition, $activeItemPosition, $activeItemWidth);
    });

})(jQuery);