( function( $ ) {

    function tcoSetSliderHeights( slider_wrap ) {
        slider_wrap.each(function() {
            var maxHeight = 0;

            $(this).find('.slide > div').each(function(j, child) {
                maxHeight = ($(child).outerHeight() > maxHeight ? $(child).outerHeight() : maxHeight);
            });
            $(this).parent().css("min-height", maxHeight)
        });
    }

    tcoSetSliderHeights( $('.tco-slider .slides') );

    $(document).resize( function() {
        tcoSetSliderHeights( $('.tco-slider .slides') );
    } );

    $(document).on("gjCasesLoaded", function(event, slider) {
        var slides = slider.find('[data-slides]');
        tcoSetSliderHeights( slides );
    });

    $(document).on("tcoSliderAjaxLoaded", function(event, slider) {
        var slides = slider.find('[data-slides]');
        tcoSetSliderHeights( slides );
    });

    setTimeout(function() {
        tcoSetSliderHeights( $('.tco-slider .slides') );
    }, 2000 );

} )( jQuery );