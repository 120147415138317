function handleMutation(mutationsList) {
    mutationsList.forEach(function(mutation) {
        if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(function(node) {
                if (node.id === 'elementor-device-mode') {
                    var elements = document.querySelectorAll('#elementor-device-mode');
                    elements.forEach(function(element) {
                        element.setAttribute('role', 'none');
                    });
                }
            });
        }
    });
}

var observer = new MutationObserver(handleMutation);
observer.observe(document.body, { childList: true, subtree: true });