(function($) {

    // Grab the content from the static header
    var $static_header = $('.site-header > .site-header__row').clone();

    // Update IDs within the cloned header
    $static_header.find('[id]').each(function(index) {
        var oldId = $(this).attr('id');
        var newId = oldId + '_' + index; // Appending the index to the old ID
        $(this).attr('id', newId);
    });

    // Add the content into the fixed header
    $('.site-header--fixed').html($static_header);

    // Re-init the menu toggle
    $('.site-header--fixed [data-toggle-offside]').click(function( event ) {
        siteOffcanvas.toggle();

        event.preventDefault();
        return false;
    });

})(jQuery);