(function($) {

    /**
     * Changes the header phone link on desktop devices to link to the contact popup
     * instead of the telephone number.
     */
    function trltChangeHeaderContactLink() {
        var headerContactLink   = $('.site-header .contact-info__link');
        var originalHref        = headerContactLink.attr('href');
        var isMobile            = window.matchMedia("only screen and (max-width: 1024px)").matches;

        if ( ! isMobile ) {
            headerContactLink.attr('href', '#headercontactpopup');
        }
        else {
            headerContactLink.attr('href', originalHref);
        }
    }


    /**
     * On mobile, change all Speak to an Attorney buttons to link to the contact popup
     */
    function trltChangePopupButtons() {
        var popupButtons    = $('.contactpopup a.button');
        var originalHref    = popupButtons.attr('href');
        var isMobile        = window.matchMedia("only screen and (max-width: 1024px)").matches;

        if ( isMobile ) {
            popupButtons.attr('href', '#headercontactpopup' );
        }
        else {
            popupButtons.attr('href', originalHref );
        }
    }


    /* Call each function on page load */
    trltChangePopupButtons();
    trltChangeHeaderContactLink();


    /* Call again on resize just in case */
    $(window).resize(function() {
        trltChangeHeaderContactLink();
        trltChangePopupButtons();
    });

})(jQuery);